<template>
  <v-card>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      persistent
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{ isEdit ? $t("app.edit") : $t("app.upload") }} {{ " " + name }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loading" @click="close(false)">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider class="mb-2" />
        <v-card-text class="pt-2">
          <v-form ref="formUpload" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="idSchoolYear"
                  :label="$t('school_year.header.school_year')"
                  :items="schoolYearList"
                  :loading="loadingSchoolYear"
                  :disabled="isEdit"
                  item-value="id"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                >
                  <template #item="{ item }">
                    {{ appendSemester(item) }}
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          :color="schoolYearInfo(item.status).color"
                          small
                          class="ml-1"
                        >
                          {{ schoolYearInfo(item.status).name }}
                        </v-icon>
                      </template>
                      <span>{{ schoolYearInfo(item.status).info }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ appendSemester(item) }}
                  </template>
                </v-select>
                <v-autocomplete
                  v-if="isAdmin"
                  v-model="idTeacher"
                  :label="$t('app.teacher')"
                  :disabled="isEdit"
                  :items="listTeacher"
                  :loading="loadingTeacher"
                  :rules="[v => !!v || $t('app.required')]"
                  item-value="id"
                  item-text="name"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                  required
                  @change="getLessons()"
                />
                <v-autocomplete
                  v-model="idSubjects"
                  :disabled="idTeacher === 0 || isEdit"
                  :label="$t('app.subjects')"
                  :items="listSubjects"
                  :loading="loadingSubjects"
                  :rules="[v => !!v || $t('app.required')]"
                  required
                  item-value="lesson"
                  item-text="lesson_name"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                  @change="getClassLesson()"
                />
                <v-select
                  v-model="idClass"
                  :disabled="idSubjects === 0 || isEdit"
                  :label="$t('app.class')"
                  :rules="[v => v.length !== 0 || $t('app.required')]"
                  :items="classList"
                  :loading="loadingClass"
                  required
                  multiple
                  item-value="id"
                  item-text="name"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                  @change="changeSubjects"
                >
                  <template slot="prepend-item">
                    <v-list-item @click="handleClickAll('class')">
                      <v-list-item-action>
                        <v-checkbox :value="selectAllClass" />
                      </v-list-item-action>
                      <v-list-item-content
                        :class="selectAllClass && 'primary--text'"
                      >
                        {{ $t("app.select_all") }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
                    <span v-if="index === 1" class="grey--text">
                      (+{{ classList.length - 1 }} {{ $t("app.others") }})
                    </span>
                  </template>
                </v-select>
                <v-select
                  v-model="modelType"
                  :disabled="idClass.length === 0 || isEdit"
                  :label="$t('app.type')"
                  :rules="[v => !!v || $t('app.required')]"
                  :items="types"
                  required
                  item-value="id"
                  item-text="name"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                />
                <ButtonUpload
                  :label="$t('e_test.upload_document')"
                  :accept="extDocument"
                  :loading="loadingFile"
                  :disabled="filesUpload.length >= 3"
                  :reset="resetUpload"
                  class="my-2"
                  multiple
                  depressed
                  @getDocument="
                    fileData => {
                      this.filesTemp = fileData;
                      this.uploadDocument();
                    }
                  "
                />
                <v-row no-gutters>
                  <v-col cols="12" class="mt-n2">
                    <span
                      class="caption"
                      v-text="
                        `${$t('silabus_rpp.file_supported')} ${extAllowed}`
                      "
                    />
                  </v-col>
                  <v-col
                    v-for="(file, index) in filesUpload"
                    :key="index"
                    cols="12"
                  >
                    <v-chip
                      :outlined="urlFile === file"
                      close
                      color="primary"
                      class="ma-2"
                      @click="urlFile = file"
                      @click:close="removeFile(filesUpload, file)"
                    >
                      {{ file.replace(/^.*[\\\/]/, "") }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="8">
                <div v-if="urlFile" class="d-flex justify-end">
                  <v-btn
                    class="gradient-primary ma-2"
                    dark
                    depressed
                    @click="openLink(urlFile)"
                  >
                    <v-icon class="mr-2">mdi-open-in-new</v-icon>
                    {{ $t("e_test.open_file") }}
                  </v-btn>
                </div>
                <v-row
                  v-if="urlFile"
                  justify="center"
                  align="center"
                  no-gutters
                  class="ma-2"
                >
                  <v-img v-if="validateImage(urlFile)" :src="urlFile" contain />
                  <iframe
                    v-else
                    :src="viewDoc(urlFile)"
                    :height="$vuetify.breakpoint.xsOnly ? '700vh' : '500px'"
                    class="white"
                    width="100%"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loading"
            color="primary"
            outlined
            @click="close(false)"
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            :loading="loading"
            class="gradient-primary"
            dark
            depressed
            @click="save"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="bottomSheet" persistent scrollable>
      <v-card height="400">
        <v-card-title>
          <span class="title font-weight-bold">
            {{ $t("silabus_rpp.dialog_confirm_title") }}
          </span>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-3">
          <v-row class="mt-3">
            <v-col cols="12" sm="5" lg="3">
              <v-row>
                <v-col cols="5" class="font-weight-bold d-flex flex-row">
                  {{ $t("app.school_year") }} <v-spacer /> :
                </v-col>
                <v-col cols="7">
                  {{ getSchoolYearName() }}
                </v-col>
                <v-col cols="5" class="font-weight-bold d-flex flex-row">
                  {{ $t("app.teacher") }} <v-spacer /> :
                </v-col>
                <v-col cols="7">
                  {{ getTeacherName() }}
                </v-col>
                <v-col cols="5" class="font-weight-bold d-flex flex-row">
                  {{ $t("app.subjects") }} <v-spacer /> :
                </v-col>
                <v-col cols="7">
                  {{ getSubjectsName() }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-10">
              <v-alert dense border="left" outlined type="warning">
                {{ $t("silabus_rpp.dialog_confirm_info") }}
              </v-alert>
            </v-col>
            <v-col cols="12" sm="5" lg="3">
              <v-row
                v-for="item in filesExist"
                :key="item.id"
                class="align-center"
              >
                <v-col cols="5" class="font-weight-bold d-flex flex-row">
                  {{ item.name }} <v-spacer /> :
                </v-col>
                <v-col cols="7">
                  <v-chip
                    v-for="(url, index) in item.files"
                    :key="index"
                    color="primary"
                    class="ma-2"
                    @click="openLink(url)"
                  >
                    {{ url.replace(/^.*[\\\/]/, "") }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loading"
            color="primary"
            class="mr-2"
            outlined
            @click="bottomSheet = false"
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            :loading="loading"
            class="gradient-primary"
            depressed
            dark
            @click="
              {
                showConfirmFileExist = false;
                save();
              }
            "
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import { get_school_year_list } from "@/api/admin/schoolClass";
import {
  teachers,
  lessons,
  classLesson,
  saveDocument,
  getDocumentItem
} from "@/api/admin/academic/silabus";
import { deleteFile, uploadFile } from "@/api/admin/admin";
import { sliceAwsPath } from "@/utils/uploadS3";
import { isImageFile } from "@/utils/validate";

export default {
  props: {
    dialog: Boolean,
    name: String,
    isEdit: Boolean,
    dataEdit: {
      type: Object,
      default: () => ({
        teacher: 0,
        lesson: 0,
        classrooms: [],
        files: [],
        schoolYear: 0,
        documentType: "OTHER"
      })
    }
  },
  components: {
    ButtonUpload: () => import("@/components/ButtonUpload")
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.resetFilter = true;
          this.resetUpload = true;
        });
        if (this.isEdit) {
          const file = this.dataEdit.files;
          if (file.length != 0 && Array.isArray(file)) {
            this.urlFile = file[0];
          }
          this.idTeacher = this.dataEdit.teacher;
          this.idSubjects = this.dataEdit.lesson;
          this.idClass = this.dataEdit.classrooms;
          this.filesUpload = this.dataEdit.files;
          this.idSchoolYear = this.dataEdit.schoolYear;
          this.modelType = this.dataEdit.documentType;
          this.getLessons();
          this.getClassLesson();
        } else if (this.isTeacher) {
          this.idTeacher = this.employeeID;
          this.getLessons();
        }
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getSchoolYear();
      if (this.isAdmin) await this.getTeachers();
    });
  },
  data() {
    return {
      types: [
        { id: "OTHER", name: this.$i18n.t("app.others") },
        { id: "SILABUS", name: "Silabus" },
        { id: "RPP", name: "RPP" }
      ],
      modelType: "OTHER",
      isSilabus: false,
      employeeID: this.$store.getters.g_user.employee_id,
      isAdmin: this.$store.getters.g_role_type === "ADMIN",
      extDocument:
        "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/pdf, image/*",
      loading: false,
      filesTemp: [],
      trashFiles: [],
      urlFile: "",
      loadingFile: false,
      resetUpload: false,
      resetFilter: false,
      loadingSchoolYear: false,
      loadingClass: false,
      loadingSubjects: false,
      loadingTeacher: false,
      idTeacher: 0,
      listTeacher: [],
      idSubjects: 0,
      listSubjects: [],
      idSchoolYear: 0,
      schoolYearList: [],
      idClass: [],
      classList: [],
      filesUpload: [],
      selectAllClass: false,
      bottomSheet: false,
      showConfirmFileExist: true,
      filesExist: [],
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      extAllowed:
        "jpg, jpeg, png, pdf, doc, docx, ppt, pptx, xls, xlsx, txt, text",
      regexExt: /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.ppt|\.pptx|\.xls|\.xlsx|\.txt|\.text)$/i
    };
  },
  methods: {
    validateUpload(files) {
      const data = [];
      files.map(d => data.push(this.regexExt.test(d.name)));
      return data.includes(true);
    },
    getTeacherName() {
      if (this.isTeacher) return this.$store.getters.g_user.fullname;
      const teacher = this.listTeacher.find(item => item.id === this.idTeacher);
      if (teacher) return teacher.name;
    },
    getSubjectsName() {
      const subjects = this.listSubjects.find(
        item => item.lesson === this.idSubjects
      );
      if (subjects) return subjects.lesson_name;
    },
    getSchoolYearName() {
      const schoolYear = this.schoolYearList.find(
        item => item.id === this.idSchoolYear
      );
      if (schoolYear) return this.appendSemester(schoolYear);
    },
    async checkFilesExist() {
      this.loading = true;
      try {
        const body = {
          teacher: this.idTeacher,
          lesson: this.idSubjects,
          classroom: this.idClass,
          document_type: this.modelType
        };
        const response = await getDocumentItem(body);
        if (response.data.code) {
          this.filesExist = response.data.data.filter(
            item => item.files.length > 0
          );
          if (this.filesExist.length) {
            this.bottomSheet = true;
          } else {
            this.showConfirmFileExist = false;
            this.save();
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("checkFileExist()\n", error);
      } finally {
        this.loading = false;
      }
    },
    changeSubjects() {
      if (this.classList.length === this.idClass.length)
        this.selectAllClass = true;
      else this.selectAllClass = false;
    },
    handleClickAll() {
      this.selectAllClass = !this.selectAllClass;
      this.idClass = [];
      if (this.selectAllClass) this.idClass = this.classList.map(r => r.id);
    },
    async getClassLesson() {
      this.loadingClass = true;
      if (!this.isEdit) {
        this.idClass = [];
        this.selectAllClass = false;
        this.$refs.formUpload.resetValidation();
      }
      try {
        const res = await classLesson({
          teacher: this.idTeacher,
          school_year: this.idSchoolYear,
          lesson: this.idSubjects
        });
        if (res.data.code) this.classList = res.data.data;
      } catch (error) {
        console.error("getClassLesson()\n", error);
      } finally {
        this.loadingClass = false;
      }
    },
    async getLessons() {
      this.loadingSubjects = true;
      if (!this.isEdit && !this.isTeacher) {
        this.idSubjects = 0;
        this.$refs.formUpload.resetValidation();
      }
      try {
        const res = await lessons({
          teacher: this.idTeacher,
          school_year: this.idSchoolYear
        });
        if (res.data.code) this.listSubjects = res.data.data;
      } catch (error) {
        console.error("getLessons()\n", error);
      } finally {
        this.loadingSubjects = false;
      }
    },
    async save() {
      // validate file exist when input data
      if (
        !this.isEdit &&
        this.filesUpload.length > 0 &&
        this.showConfirmFileExist
      ) {
        this.checkFilesExist();
      } else {
        const body = {
          teacher: this.idTeacher,
          lesson: this.idSubjects,
          classrooms: this.idClass,
          files: this.filesUpload,
          document_type: this.modelType
        };
        if (this.$refs.formUpload.validate()) {
          try {
            this.loading = true;
            const response = await saveDocument(body);
            if (response.data.code) {
              const msg = this.isEdit ? "update" : "add";
              this.snackBar(
                true,
                this.$i18n.t(`silabus_rpp.msg.success.document.${msg}`)
              );
              this.close(true);
            } else {
              this.snackBar(false, response.data.message);
            }
            this.bottomSheet = false;
          } catch (error) {
            console.error("save()\n", error);
          } finally {
            this.loading = false;
          }
        }
      }
    },
    viewDoc(name) {
      if (/\.(pdf)$/i.test(name)) {
        return `https://docs.google.com/viewer?url=${name}&embedded=true`;
      } else if (/\.(doc|docx|xlsx|ppt|pptx)$/i.test(name)) {
        return `https://view.officeapps.live.com/op/view.aspx?src=${name}`;
      } else {
        return name;
      }
    },
    validateImage: name => isImageFile(name),
    async removeFile(array, item) {
      if (item === this.urlFile) this.urlFile = "";
      array.splice(array.indexOf(item), 1);
      this.trashFiles.push(item);
    },
    async removeFileServer(files) {
      if (files.length != 0) {
        files.map(async file => {
          const path = sliceAwsPath(file);
          await deleteFile(path, this.name.toUpperCase());
        });
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    filterFiles(files) {
      return files.filter(file => this.regexExt.test(file.name));
    },
    openLink: url => window.open(url, "_blank"),
    uploadDocument() {
      const files = this.filesTemp;
      const filesUploadLength = this.filesUpload.length;
      new Promise(async () => {
        if (filesUploadLength <= 3 && files.length <= 3) {
          const countFiles = filesUploadLength + files.length;
          let resultFiles = [];
          if (this.validateUpload(files)) {
            if (countFiles >= 4) {
              const filterFiles = this.filterFiles(files);
              for (let i = 0; i < 3 - filesUploadLength; i++) {
                resultFiles.push(filterFiles[i]);
              }
            } else {
              resultFiles = this.filterFiles(files);
            }
            this.loadingFile = true;
            await Promise.all(
              resultFiles.map(async (file, index) => {
                let body = new FormData();
                body.set("path", this.name.toLowerCase());
                body.set("module", this.name.toUpperCase());
                body.append("file", file);

                const res = await uploadFile(body);
                const data = res.data;
                if (data.code) {
                  const url = data.data.path;
                  if (!this.urlFile && index === 0) this.urlFile = url;
                  this.filesUpload.push(url);
                }
              })
            );
            this.loadingFile = false;
            this.resetUpload = true;
          } else {
            this.snackBar(
              false,
              this.$i18n.t("silabus_rpp.msg.error.rule_upload")
            );
          }
        } else {
          this.snackBar(false, this.$i18n.t("e_test.max_upload"));
        }
      });
    },
    async getTeachers() {
      this.loadingTeacher = true;
      try {
        const res = await teachers();
        if (res.data.code) this.listTeacher = res.data.data;
      } catch (error) {
        console.error("getTeacher()\n", error);
      } finally {
        this.loadingTeacher = false;
      }
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    appendSemester(item) {
      return `${item.start_year} / ${item.end_year}, semester ${item.semester}`;
    },
    async getSchoolYear() {
      this.loadingSchoolYear = true;
      try {
        const res = await get_school_year_list(false);
        if (res.data.code) {
          const data = res.data.data;
          this.schoolYearList = data;
          this.idSchoolYear = this.dataEdit.schoolYear;
        }
      } catch (error) {
        console.error("getSchoolYear()\n", error);
      } finally {
        this.loadingSchoolYear = false;
      }
    },
    reset() {
      this.resetFilter = false;
      this.resetUpload = false;
      this.showConfirmFileExist = true;
      this.urlFile = "";
      this.filesUpload = [];
      this.filesTemp = [];
      this.trashFiles = [];
      this.idClass = [];
      this.filesExist = [];
      this.idTeacher = 0;
      this.idSubjects = 0;
      this.modelType = "OTHER";
      this.$refs.formUpload.resetValidation();
    },
    close(isSave) {
      if (isSave) {
        // remove old file
        let fileExist = [];
        this.filesExist.map(item => item.files.map(url => fileExist.push(url)));
        this.removeFileServer(fileExist);
        this.removeFileServer(this.trashFiles);
      } else if (!this.isEdit) {
        this.removeFileServer(this.filesUpload);
        this.removeFileServer(this.trashFiles);
      }
      this.reset();
      this.$emit("close");
    }
  }
};
</script>
